import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "amazon-status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
    standalone: false
})
export class StatusComponent {
  public message: string;
  public isUploadStatus = false;
  public statusData = [];

  constructor(
    private dialogRef: MatDialogRef<StatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.statusData = this.data.hasOwnProperty("response")
      ? this.data.response
      : this.data;
    console.log(this.statusData, this.data);
    if (
      this.data.category === "csv-upload" &&
      (this.data.response.error.success === false ||
        this.data.response.hasOwnProperty("errors"))
    ) {
      this.statusData = this.data.response.hasOwnProperty("error")
        ? this.data.response.error.error.errors
        : this.data.response.errors;
      this.message = `Failed to upload ${
        this.statusData && this.statusData.length ? this.statusData.length : ""
      } data`;
    } else {
      this.message = "File uploaded successfully";
    }
  }

  /**
   * @method - called when the modal is closed
   * @param param - status
   */
  closeModal(status) {
    this.dialogRef.close(status);
  }
}
