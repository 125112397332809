import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";

import { AppCommonService } from "../app-common.service";
import { Auth, Login, UpdateSession } from "./login";
import { SharedService } from "../../utils/services/shared.service";
import { MatSnackBar } from "@angular/material/snack-bar";

// declare let $: any;

@Component({
    // tslint:disable-next-line:component-selector
    selector: "amazon-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    standalone: false
})
export class LoginComponent implements OnInit {
  @SessionStorage("auth") public authToken: Auth = new Auth();
  @SessionStorage("moduleRoles") public myModules: any;
  public login: Login = new Login();
  public sessionObj: UpdateSession = new UpdateSession();
  public loader = false;
  public showPassword = false;

  constructor(
    private router: Router,
    private commonService: AppCommonService,
    private sessionStorageService: SessionStorageService,
    private snackBar: MatSnackBar,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sessionStorageService.clear();
    sessionStorage.clear();
  }

  onSubmit() {
    this.sharedService.display(true);
    this.commonService.login("login", this.login).subscribe(
      (response) => {
        console.log(response);
        if (response.success) {
          this.authToken = new Auth(response.payload);
          this.createSession();
          this.sharedService.display(false);
        } else {
          this.sharedService.display(false);
          this.snackBar.open(
            response.error && response.error.message
              ? response.error.message
              : window["serverError"],
            "okay",
            window["snackBarConfig"]
          );
        }
      },
      (error) => {
        this.sharedService.display(false);
        if (error.error) {
          this.snackBar.open(
            error.error.message ? error.error.message : window["serverError"],
            "okay",
            window["snackBarConfig"]
          );
        } else {
          this.snackBar.open("Server Down", "okay", window["snackBarConfig"]);
        }
      }
    );
  }

  /* update the session */
  createSession() {
    this.sessionObj.token = this.authToken.token;
    this.sessionObj.type = this.authToken.tokenType;
    this.sessionObj.userid = this.authToken.userDetails.userid;
    this.updateSession();
  }

  updateSession() {
    this.commonService.update("session/log", this.sessionObj).subscribe(
      (response) => {
        if (response.success) {
          if (this.authToken.userDetails.userid == "iosuser") {
            this.router.navigate(["/delete-user"]);
          } else {
            this.getModuleRoute();
          }
        } else {
          this.snackBar.open(
            response.error.message,
            "okay",
            window["snackBarConfig"]
          );
        }
      },
      (error) => {
        this.snackBar.open(
          error.error.message,
          "okay",
          window["snackBarConfig"]
        );
      }
    );
  }

  /* get route */
  getModuleRoute() {
    this.commonService.login("user/getUserModules", {}).subscribe(
      (response) => {
        const self = this;
        if (response.success && response.payload) {
          this.myModules = response.payload;
          response.payload.every(function (element) {
            switch (element.modulename) {
              case "Dashboard":
                self.router.navigate(["/home/dashboard"]);
                break;
              case "Manage Feedback":
                self.router.navigate(["/feedback/viewfeedback"]);
                break;
              case "Keywords":
                self.router.navigate(["/keywords/manage-keyword"]);
                break;
              case "Manage Master":
                element.resources.forEach((e) => {
                  if (e.resourcename === "roles") {
                    self.router.navigate(["/master/roles/viewroles"]);
                  } else {
                    self.router.navigate(["/master/category/viewmaster"]);
                  }
                });
                break;
              case "Manage User":
                self.router.navigate(["/usermanagement/users/listusers"]);
                break;
              case "Leads":
                self.router.navigate(["/leadmanagement"]);
                break;
              case "Manage Station/Stores":
                element.resources.forEach((e) => {
                  if (e.resourcename === "stores") {
                    self.router.navigate([
                      "/storemanagement/stores/managestores",
                    ]);
                  } else {
                    self.router.navigate([
                      "/storemanagement/stations/managestations",
                    ]);
                  }
                });
                break;
              case "Manage Flags/Actions":
                self.router.navigate(["/actions/all"]);
                break;
              case "Report":
                self.router.navigate(["/reports/dailyreports"]);
                break;
              default:
                break;
            }
            return false;
          });
        } else {
          this.snackBar.open(
            response.error.message,
            "okay",
            window["snackBarConfig"]
          );
        }
      },
      (error) => {
        this.snackBar.open(
          error.error.message,
          "okay",
          window["snackBarConfig"]
        );
      }
    );
  }

  /* toggle password */
  toggleInputType() {
    this.showPassword = !this.showPassword;
  }
}
