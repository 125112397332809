import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightString',
    standalone: false
})
export class HighlightStringPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args || args.trim() === '') { return value; }

    // the returned value is binded to the innerHtml - replace the code added for rendering code as it is
    let val = value;
    if (val.match('<pre>') !== null) {
      val = val.replace('<pre>', '');
      val = val.replace('</pre>', '');
      val = val.replace(/\&lt\;/g, '<');
      val = val.replace(/\&gt\;/g, '>');
    }
    let re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive. 
    return val.replace(re, "<mark class='mark-bg'>" + args + "</mark>");
  }

}
