// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 20px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h5,
h6 {
  font-weight: 600;
  margin: 2rem 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByaXZhY3ktcG9saWN5LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksYUFBQTtFQUNBLFlBQUE7RUFDQSxrQ0FBQTtFQUNBLGVBQUE7QUFDSjs7QUFFQTs7Ozs7RUFLSSxnQkFBQTtFQUNBLGNBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7QUFDSiIsImZpbGUiOiJwcml2YWN5LXBvbGljeS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICAgIHBhZGRpbmc6IDIwcHg7XG4gICAgY29sb3I6IGJsYWNrO1xuICAgIGZvbnQtZmFtaWx5OiBcIlBvcHBpbnNcIiwgc2Fucy1zZXJpZjtcbiAgICBmb250LXNpemU6IDE2cHg7XG59XG5cbmgxLFxuaDIsXG5oMyxcbmg1LFxuaDYge1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gICAgbWFyZ2luOiAycmVtIDA7XG59XG5cbmgxIHtcbiAgICBmb250LXNpemU6IDMwcHg7XG59XG5cbmgyIHtcbiAgICBmb250LXNpemU6IDI4cHg7XG59XG5cbmgzIHtcbiAgICBmb250LXNpemU6IDI0cHg7XG59XG5cbmg1IHtcbiAgICBmb250LXNpemU6IDIwcHg7XG59XG5cbmg2IHtcbiAgICBmb250LXNpemU6IDE4cHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/wow/privacy-policy/privacy-policy.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,kCAAA;EACA,eAAA;AACJ;;AAEA;;;;;EAKI,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;AACA,g9BAAg9B","sourcesContent":[".container {\n    padding: 20px;\n    color: black;\n    font-family: \"Poppins\", sans-serif;\n    font-size: 16px;\n}\n\nh1,\nh2,\nh3,\nh5,\nh6 {\n    font-weight: 600;\n    margin: 2rem 0;\n}\n\nh1 {\n    font-size: 30px;\n}\n\nh2 {\n    font-size: 28px;\n}\n\nh3 {\n    font-size: 24px;\n}\n\nh5 {\n    font-size: 20px;\n}\n\nh6 {\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
