import { Component, OnInit } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'amazon-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: false
})
export class LayoutComponent implements OnInit {
  @SessionStorage('auth') public user;

  constructor(public router: Router) {
    // redirect to login if the authorisation token is not found
    if (!this.user) {
        this.router.navigateByUrl('/login');
      }
   }

  ngOnInit() {
  }



}
