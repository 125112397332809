import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"; // Updated import
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorService, HttpService } from "./services/";
import { HighlightStringPipe } from "./pipes/highlight-string/highlight-string.pipe";
import { MatNativeDateModule } from "@angular/material/core";
import { SafeHtmlPipe } from "./pipes/html-sanitizer/safe-html.pipe";
import { ViewLeadComponent } from "./dialog-modals/view-lead/view-lead.component";
import { AddleadComponent } from "./dialog-modals/addlead/addlead.component";
import { AssignleadComponent } from "./dialog-modals/assignlead/assignlead.component";
import { FilterLeadComponent } from "./dialog-modals/filter-lead/filter-lead.component";
import { RejectleadComponent } from "./dialog-modals/rejectlead/rejectlead.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ValidateinputDirective } from "./directives/validateinput.directive";
import { ModalService } from "./dialog-modals/modal.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { StatusComponent } from "./components/status/status.component";
// import { Daterangepicker } from "ng2-daterangepicker";
import { DownloadFilterComponent } from "./dialog-modals/download-filter/download-filter.component";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({ declarations: [
        ConfirmationComponent,
        HighlightStringPipe,
        StatusComponent,
        SafeHtmlPipe,
        ViewLeadComponent,
        AddleadComponent,
        AssignleadComponent,
        FilterLeadComponent,
        RejectleadComponent,
        ValidateinputDirective,
        DownloadFilterComponent,
    ],
    exports: [
        HighlightStringPipe,
        SafeHtmlPipe,
        ValidateinputDirective,
        ViewLeadComponent,
        AddleadComponent,
        AssignleadComponent,
        FilterLeadComponent,
        RejectleadComponent,
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRadioModule,
        NgSelectModule,
        // Daterangepicker,
        MatFormFieldModule,
        MatNativeDateModule], providers: [ErrorService, ModalService, HttpService, provideHttpClient(withInterceptorsFromDi())] })
export class UtilsModule {}
