import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
    selector: "amazon-confirmation",
    templateUrl: "./confirmation.component.html",
    styleUrls: ["./confirmation.component.scss"],
    standalone: false
})
export class ConfirmationComponent implements OnInit {
  public confirmationMessage = "Please Confirm";
  public successButtonLabel = "Ok";
  public cancelButtonLabel = "Cancel";

  constructor(
    private dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data, "check data");
    if (this.data) {
      this.confirmationMessage = this.data.hasOwnProperty("confirmationMessage")
        ? data.confirmationMessage
        : this.confirmationMessage;
      this.successButtonLabel = this.data.hasOwnProperty("successButtonLabel")
        ? data.successButtonLabel
        : this.successButtonLabel;
      this.cancelButtonLabel = this.data.hasOwnProperty("cancelButtonLabel")
        ? data.cancelButtonLabel
        : this.cancelButtonLabel;
    }
  }

  ngOnInit() {}
  closeModal(param) {
    param == "success"
      ? this.dialogRef.close(true)
      : this.dialogRef.close(false);
  }
}
