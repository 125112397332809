import { Component } from '@angular/core';

@Component({
    selector: 'amazon-privacy-policy',
    imports: [],
    templateUrl: './privacy-policy.component.html',
    styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

}
